import React from "react";

class CircularProgressBar extends React.Component {
  render() {
    const { ringSize=90, strokeWidth=5, percentage, strokeColor='#1EC6D5', label,sublabel } = this.props;
    const radius = (ringSize - strokeWidth) / 2;
    const viewBox = `0 0 ${ringSize} ${ringSize}`;
    const dArr = radius * Math.PI * 2;
    const dOffset = dArr - (dArr * percentage) / 100;
    return (
      <div className="progress-circle">
      <div className="progress-circle-text">{label}</div>
      <div className="progress-circle-subtext">{sublabel}</div>
        <svg width={ringSize} height={ringSize} viewBox={viewBox}>
          <circle
            className="circlebackground"
            cx={ringSize / 2}
            cy={ringSize / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            fill="none"
            stroke="#ddd"
          />
          <circle
            className="circleProgress"
            cx={ringSize / 2}
            cy={ringSize / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            transform={`rotate(-90 ${ringSize / 2} ${ringSize / 2})`}
            fill={percentage !== "100" && "none"}
            stroke={percentage === "100" ? "none" : strokeColor || "#406ae8"}
            style={{
              strokeDasharray: dArr,
              strokeDashoffset: dOffset,
            }}
          />
        </svg>
      </div>
    );
  }
}
export default CircularProgressBar;
